<template>
  <FormCard
    :title="factor.name ? factor.name : 'Novo fator'"
    :status="factor.status"
    @cancel="$router.push({ name: 'factor' })"
    @save="validate"
  >
    <v-form
      class="ml-3"
      ref="form"
    >
      <SectionTitle title="Custo do produto" />
      <v-row dense>
        <v-col
          cols="12"
          md="2"
        >
          <v-text-field
            v-model="factor.start_value"
            label="De"
            placeholder="De"
            v-mask="['#,##', '##,##', '###,##']"
            dense
            rounded
            outlined
          />
        </v-col>
        <v-col
          cols="12"
          md="2"
        >
          <v-text-field
            v-model="factor.end_value"
            label="Até"
            placeholder="Até"
            v-mask="['#,##', '##,##', '###,##']"
            dense
            rounded
            outlined
          />
        </v-col>

        <v-col
          cols="12"
          md="12"
        >
          <SectionTitle title="Tabela de Fatores" />

          <FactorCostTableRow
            v-for="(row, idx) in factor.rows"
            :key="idx"
            :row="row"
            :length="factor.rows.length"
            @removeRow="removeRow(row)"
          />

          <v-btn
            @click="addRow"
            icon
            dark
            class="primary mt-2"
          >
            <v-icon small>fas fa-plus</v-icon>
          </v-btn>
        </v-col>
      </v-row>
    </v-form>
  </FormCard>
</template>

<script>
export default {
  props: {
    valid: Boolean,
    factor: {
      type: Object,
      required: true,
    },
  },
  components: {
    FormCard: () => import('@/components/FormCard'),
    SectionTitle: () => import('@/components/SectionTitle'),
    FactorCostTableRow: () => import('./FactorCostTableRow'),
  },
  data: () => ({
    rows: [{}],
    profile: null,
  }),
  methods: {
    validate() {
      let isValid = this.$refs.form.validate()
      if (isValid) this.$emit('save')
    },
    addRow() {
      this.factor.rows.push({})
    },
    removeRow(row) {
      let idx = this.factor.rows.indexOf(row)
      this.factor.rows.splice(idx, 1)
    },
    getUserProfile() {
      this.$api
        .get(`people/${this.$cookies.get('isAuth')}`, {
          headers: {
            Authorization: `Bearer ${this.$cookies.get('token')}`,
          },
        })
        .then((res) => {
          this.user = res.data.data
          this.$store.commit('setProfile', this.user.profile)
        })
        .catch((err) => {
          console.log(err)
        })
    },
  },
  created() {
    if (this.$cookies.get('type') == 'user' && this.type == 'edit') {
      if (this.$store.state.profile.length == 0) {
        this.getUserProfile()
      }
    }
  },
}
</script>